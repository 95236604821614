import React from 'react';
import {Route, Switch} from "react-router-dom";
import PasswordReset from "../pages/auth/PasswordReset";
import Login from "../pages/auth/Login";

const Auth = ({match}) => (
    <Switch>
        <Route path={match.url + '/login'} component={Login}/>
        <Route path={match.url + "/passwordReset/:user/:token"} component={PasswordReset}/>
    </Switch>
);

export default Auth;
