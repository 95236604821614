import React from 'react';
import {Helmet} from "react-helmet";
import appConfig from "./config/appConfig";
import Router from "./Router";
import {createStore} from "redux";
import {Provider} from "react-redux";
import reducers from "./reducers";
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {PersistGate} from 'redux-persist/integration/react'

const persistedReducer = persistReducer(
    {
        key: 'root',
        storage,
    },
    reducers
);
const store = createStore(persistedReducer);
const persistor = persistStore(store);

function App() {
    // noinspection HtmlUnknownTarget
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <title>{appConfig.title}</title>
                <link rel="shortcut icon" href="/api/favicon/64/64"/>
                <meta name="theme-color" content={appConfig.theme.colors[0].toString() || "#000000"}/>
                {/*
                  * manifest.json provides metadata used when your web app is added to the
                  * home screen on Android. See https://developers.google.com/web/fundamentals/web-app-manifest/
                  */}
                <link rel="manifest" href="/api/manifest"/>
                {/*
                  * iOS related tags from https://speckyboy.com/creating-a-mobile-web-application-with-meta-tags/
                  */}
                <meta name="apple-mobile-web-app-capable" content="yes"/>
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"/>
                <link rel="apple-touch-icon" sizes="72x72" href="/api/favicon/72/72"/>
                <link rel="apple-touch-icon" sizes="114x114" href="/api/favicon/114/114"/>
                <link rel="apple-touch-icon" href="/api/favicon/57/57"/>
            </Helmet>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router/>
                </PersistGate>
            </Provider>
        </div>
    );
}

export default App;
