import React from 'react';
import PropTypes from 'prop-types';
import {css, StyleSheet} from 'aphrodite';
import LoginComponent from '../../components/User/Login';

const styles = StyleSheet.create({
    root: {},
});

const Login = () => (
    <div className={css(styles.root)}>
        <h1>Login</h1>
        <LoginComponent />
    </div>
);

Login.defaultProps = {
    prop: 'value',
};

Login.propTypes = {
    prop: PropTypes.any,
};

export default Login;
