import React from 'react';
import PropTypes from 'prop-types';
import {css, StyleSheet} from 'aphrodite';
import appConfig from '../config/appConfig';
import {ContactForm, Hero} from "mdt-components";
import Scroll from 'react-scroll';

const styles = StyleSheet.create({
    heroHeader: {
        fontSize: '4em',
    },
    heroLogo: {
        maxWidth: '80%',
        marginTop: appConfig.theme.spacingUnit * 4,
    },
    heroTagLine: {
        fontSize: '1.75em',
        fontWeight: 300,
        fontStyle: 'italic',
    },
    heroText: {
        fontSize: '1.5em',
        fontWeight: 400,
    },
    heroLink: {
        color: 'inherit',
        textDecoration: 'none',

        ':hover': {
            textDecoration: 'underline',
        },
    },
    infoBox: {
        ...appConfig.theme.boxPadding(2),
        textAlign: 'center',
        fontSize: '200%',
        fontWeight: 'lighter',
        backgroundColor: appConfig.theme.colors[0].darker().toString(),
        color: appConfig.theme.colors[0].darker().getContrastText().toString()
    },
    contactBox: {
        ...appConfig.theme.boxPadding(4),
        textAlign: 'center',
    },
    footer: {
        ...appConfig.theme.boxPadding(2),

        backgroundColor: appConfig.theme.colors[1].toString(),
        color: appConfig.theme.colors[1].getContrastText().toString(),

        textAlign: 'center',
        fontSize: '0.8em',
    }
});

class Home extends React.Component {
    scrollToContact() {
        Scroll.scroller.scrollTo('contactForm', {
            duration: 500,
            delay: 100,
            smooth: true,
        });
    }

    render() {
        return (
            <div>
                <Hero
                    backgroundColor={'#fff'}
                    image={'/herobgprinter.jpg'}
                    minHeight={'75vh'}
                >
                    <img className={css(styles.heroLogo)} src={'/logo.png'} alt={'Ercall Services'}/>
                    <h1 className={css(styles.heroHeader)}>Ercall Services</h1>
                    <p className={css(styles.heroTagLine)}>
                        Keeping Your Business Printing
                    </p>
                    <p className={css(styles.heroText)}>
                        <a className={css(styles.heroLink)}
                           href={'mailto:sales@ercallservices.co.uk'}>sales@ercallservices.co.uk</a>
                    </p>
                    <p className={css(styles.heroText)}>
                        07967 191 973
                    </p>
                    <p>
                        <button onClick={this.scrollToContact.bind(this)}>Get In Touch</button>
                    </p>
                </Hero>
                <div className={css(styles.infoBox)}>
                    <p>
                        We offer the full range of managed print services.
                    </p>
                    <p>
                        Professional services with a personal touch.
                    </p>
                </div>
                <div className={css(styles.contactBox)}>
                    <Scroll.Element name={'contactForm'}/>
                    <h2>Get In Touch</h2>
                    <ContactForm
                        contactEndpoint={'ercallservices'}
                        inputs={[
                            {label: 'Name', required: true},
                            {label: 'Email', type: 'email', required: true},
                            {label: 'Phone'},
                            {label: 'Message', type: 'textarea'},
                        ]}
                    />
                </div>
                <div className={css(styles.footer)}>
                    &copy; Ercall Services {(new Date()).getFullYear()}
                </div>
            </div>
        );
    }
}

Home.defaultProps = {
    prop: 'value',
};

// noinspection JSUnresolvedVariable
Home.propTypes = {
    prop: PropTypes.any,
};

export default Home;
