import React from 'react';
import PropTypes from 'prop-types';
import {css, StyleSheet} from 'aphrodite';
import appConfig from "../config/appConfig";

const styles = StyleSheet.create({
    root: {
        ...appConfig.theme.boxPadding(),
        backgroundColor: '#ffaaaa',
    }
});

class ErrorBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
        }
    }

    clearErrors() {
        this.setState({errors: []});
    }

    addError(error) {
        if (Array.isArray(error)) {
            for(let i = 0; i < error.length; i++ ) {
                this.addError(error[i]);
            }
        } else {
            this.setState((oldState) => {
                const newState = Object.assign({}, oldState);
                newState.errors.push(error);

                return newState;
            });
        }
    }

    render() {
        if (this.state.errors.length === 0) {
            return null;
        }

        return (
            <div className={css(styles.root)}>
                {this.state.errors.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
            </div>
        )
    }
}

ErrorBox.defaultProps = {
    prop: 'value',
};

ErrorBox.propTypes = {
    prop: PropTypes.any,
};

export default ErrorBox;
