import Color from "config-colors";

const SPACING_UNIT = 8;
const CONTENT_WIDTH  = 960;

const AppConfig = {
    title: "Ercall Services",
    shortTitle: 'Ercall Services',

    theme: {
        colors: [
            new Color("#009fe3"),
            new Color("#00628b"),
        ],
        spacingUnit: SPACING_UNIT,
        boxPadding: (size) => ({
            paddingTop: SPACING_UNIT * (size || 1),
            paddingBottom: SPACING_UNIT * (size || 1),
            paddingLeft: SPACING_UNIT * 2 * (size || 1),
            paddingRight: SPACING_UNIT * 2 * (size || 1),
        }),
        boxMargin: (size) => ({
            marginTop: SPACING_UNIT * (size || 1),
            marginBottom: SPACING_UNIT * (size || 1),
            marginLeft: SPACING_UNIT * 2 * (size || 1),
            marginRight: SPACING_UNIT * 2 * (size || 1),
        }),
        contentBox: {
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: CONTENT_WIDTH,
        },
        contentWidth: CONTENT_WIDTH,
    },
};

export default AppConfig;
