import React from 'react';
import PropTypes from 'prop-types';
import {css, StyleSheet} from 'aphrodite';
import axios from 'axios';
import {userLogin} from "../../actions";
import {connect} from "react-redux";
import ErrorBox from "../ErrorBox";
import SuccessBox from "../SuccessBox";
import {withRouter} from "react-router-dom";

const styles = StyleSheet.create({
    root: {},
});

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            forgotPassword: false,
        }
    }

    validate() {
        this.refs.errors.clearErrors();

        const errors = [];

        if (this.state.email === '') {
            errors.push('Enter your email address');
        }

        if (this.state.password === '' && !this.state.forgotPassword) {
            errors.push('Enter your password');
        }

        this.refs.errors.addError(errors);

        return errors.length === 0;
    }

    async login(e) {
        e.preventDefault();

        if(!this.validate()) {
            return;
        }

        try {
            const {data} = await axios.post(
                '/api/auth/login',
                {
                    username: this.state.email,
                    password: this.state.password,
                }
            );

            if (!data.success) {
                this.refs.errors.addError(data.error);
                return;
            }

            this.props.userLogin(data.user);

            if(this.props.redirect) {
                this.props.history.push(this.props.redirect);
            }
        } catch (error) {
            console.log(error);
            this.refs.errors.addError('An unexpected error occurred. Please try again later.');
        }
    }

    async forgotPassword(e) {
        e.preventDefault();

        if(!this.validate()) {
            return;
        }

        try {
            const {data} = await axios.post(
                '/api/auth/passwordReset',
                {
                    email: this.state.email,
                }
            );

            if (!data.success) {
                this.refs.errors.addError(data.error);
                return;
            }

            this.refs.success.setMessage('We\'ve sent an email to your backup email address ' + data.backupEmail);
        } catch (error) {
            console.log(error);
            this.refs.errors.addError('An unexpected error occurred. Please try again later.');
        }
    }

    renderLoginForm() {
        return (
            <form onSubmit={this.login.bind(this)} className={css(styles.root)}>
                <h3>Login</h3>
                <ErrorBox ref={"errors"} />
                <SuccessBox ref={"success"} />
                <div>
                    <label>Email:</label>
                    <input type={"email"}
                           required
                           value={this.state.email}
                           onChange={(e) => (
                               this.setState({email: e.target.value})
                           )}/>
                </div>
                <div>
                    <label>Password:</label>
                    <input type={"password"}
                           required
                           value={this.state.password}
                           onChange={(e) => (
                               this.setState({password: e.target.value})
                           )}/>
                </div>
                <div>
                    <button type="submit">Login</button>
                </div>
                <div>
                    <button className={'link'} onClick={() => (this.setState({forgotPassword: !this.state.forgotPassword}))}>I've forgotten my password</button>
                </div>
            </form>
        )
    }

    renderForgotPasswordForm() {
        return (
            <form onSubmit={this.forgotPassword.bind(this)} className={css(styles.root)}>
                <h3>Forgot Password</h3>
                <ErrorBox ref={"errors"} />
                <SuccessBox ref={"success"} />
                <div>
                    <label>Email:</label>
                    <input type={"email"}
                           required
                           value={this.state.email}
                           onChange={(e) => (
                               this.setState({email: e.target.value})
                           )}/>
                </div>
                <div>
                    <button type="submit">Submit</button>
                </div>
                <div>
                    <button className={'link'} onClick={() => (this.setState({forgotPassword: !this.state.forgotPassword}))}>&lt;&lt; Back</button>
                </div>
            </form>
        )
    }

    render() {
        if(this.state.forgotPassword) {
            return this.renderForgotPasswordForm();
        } else {
            return this.renderLoginForm();
        }
    }
}

Login.defaultProps = {
    prop: 'value',
};

Login.propTypes = {
    prop: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => {
    return {
        userLogin: (user) => {
            dispatch(userLogin(user));
        },
    };
};

export default withRouter(connect(null, mapDispatchToProps)(Login));
