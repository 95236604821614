import React from 'react';
import PropTypes from 'prop-types';
import {css, StyleSheet} from 'aphrodite';
import appConfig from "../config/appConfig";

const styles = StyleSheet.create({
    root: {
        ...appConfig.theme.boxPadding(),
        backgroundColor: '#aaffaa',
    }
});

class SuccessBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
        }
    }

    clearSuccess() {
        this.setState({message: ''});
    }

    setMessage(message) {
        this.setState({message});
    }

    render() {
        if (this.state.message === '') {
            return null;
        }

        return (
            <div className={css(styles.root)}>
                {this.state.message}
            </div>
        )
    }
}

SuccessBox.defaultProps = {
    prop: 'value',
};

SuccessBox.propTypes = {
    prop: PropTypes.any,
};

export default SuccessBox;
