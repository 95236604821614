import { combineReducers } from 'redux';
import * as actions from './actions';

function user(state = null, action) {
    switch(action.type) {
        case actions.USER_LOGIN:
            return action.user;
        case actions.USER_LOGOUT:
            return null;
        default:
            return null;
    }
}

export default combineReducers({
    user,
});
