import React from 'react';
import PropTypes from 'prop-types';
import {css, StyleSheet} from 'aphrodite';
import ResetPassword from "../../components/User/ResetPassword";
import appConfig from "../../config/appConfig";

const styles = StyleSheet.create({
    root: {
        ...appConfig.theme.contentBox,
    },
});

const PasswordReset = () => (
    <div className={css(styles.root)}>
        <h1>Password Reset</h1>
        <ResetPassword/>
    </div>
);

PasswordReset.defaultProps = {
    prop: 'value',
};

PasswordReset.propTypes = {
    prop: PropTypes.any,
};

export default PasswordReset;
