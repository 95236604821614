import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Home from "../pages/Home";
import Auth from "./Auth";

const Router = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path={"/"} component={Home}/>

            <Route path={"/auth"} component={Auth}/>

            <Route>
                <Redirect to="/"/>
            </Route>
        </Switch>
    </BrowserRouter>
);

export default Router;
