import React from 'react';
import PropTypes from 'prop-types';
import {css, StyleSheet} from 'aphrodite';
import ErrorBox from "../ErrorBox";
import ReactPasswordStrength from "react-password-strength";
import axios from 'axios';
import {withRouter} from "react-router-dom";
import validatePassword from "./validatePassword";
import SuccessBox from "../SuccessBox";
import Login from "./Login";

const styles = StyleSheet.create({
    root: {},
});

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            isValid: false,
            confirm: '',

            resetSuccessful: false,
        }
    }

    newPasswordOnChange({score, password, isValid}) {
        console.log(isValid);
        this.setState({
            password,
            isValid,
        });
    }

    async resetPassword(e) {
        e.preventDefault();

        this.refs.errors.clearErrors();

        const errors = validatePassword(this.state.password, this.state.confirm, this.state.isValid);
        if (errors.length > 0) {
            return this.refs.errors.addError(errors);
        }

        try {
            const postData = {
                user: this.props.match.params.user,
                token: this.props.match.params.token,
                password: this.state.password,
            };

            const {data} = await axios.post(
                '/api/auth/doPasswordReset',
                postData
            );

            if (data.success) {
                this.refs.success.setMessage('Password reset successful. You can now log in with your new password.');
                this.setState({resetSuccessful: true});
            } else {
                this.refs.errors.addError(data.error);
            }

        } catch (error) {
            console.error(error);
            this.refs.errors.addError('An unexpected error occurred. Please try again later');
        }
    }

    renderResetForm() {
        return (
            <div>
                <p>You're here because you've requested a password reset.</p>
                <p>Simply enter, and confirm, your new chosen password and click 'Reset Password' at the bottom of the
                    form.</p>
                <p><strong>Please Note:</strong> Your password must conform to minimum password strength levels.</p>
                <form onSubmit={this.resetPassword.bind(this)}>
                    <div>
                        <label>New Password:</label>
                        <ReactPasswordStrength
                            className="inputContainer"
                            minLength={8}
                            minScore={2}
                            scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                            changeCallback={this.newPasswordOnChange.bind(this)}
                        />
                    </div>
                    <div>
                        <label>Confirm New Password:</label>
                        <input
                            type="password"
                            value={this.state.confirm}
                            onChange={(e) => (this.setState({confirm: e.target.value}))}
                        />
                    </div>
                    <button type="submit">Reset Password</button>
                </form>
            </div>
        )
    }

    renderLoginForm() {
        return (
            <Login redirect={'/'} />
        )
    }

    render() {
        return (
            <div className={css(styles.root)}>
                <SuccessBox ref={'success'}/>
                <ErrorBox ref={'errors'}/>
                {this.state.resetSuccessful ? this.renderLoginForm() : this.renderResetForm()}
            </div>
        );
    }
}

ResetPassword.defaultProps = {
    prop: 'value',
};

ResetPassword.propTypes = {
    prop: PropTypes.any,
};

export default withRouter(ResetPassword);
