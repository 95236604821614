function validatePassword(password, confirm, isValid) {
    let errors = [];

    if (password === '' || !isValid) {
        errors.push('Choose a new password that is at least "good"');
    }
    if (confirm === '') {
        errors.push('Enter your new password again in the Confirm Password box');
    }
    if (password !== '' && confirm !== '' && password !== confirm) {
        errors.push('Your New Password and Confirm Password need to match');
    }

    return errors;
}

export default validatePassword;
